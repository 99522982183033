// VideoInterview
import React, { useEffect, useRef, useState } from "react";
import { IoLogoWechat } from "react-icons/io5";
import { FaRecordVinyl } from "react-icons/fa6";
import faang_white_logo from "../../assests/Images/asendia_logo_text_white.png";
import faanglogoName from "../../assests/Images/faang_white.png";
import { IoMdMic } from "react-icons/io";
import { IoMdMicOff } from "react-icons/io";
import { MdCall } from "react-icons/md";
import { RiRobot3Fill } from "react-icons/ri";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import randomImage from "../../assests/Images/happyman.jpg";
import { fetchReport } from "../../Services/ApiEnpoints";
import { useLocation } from "react-router-dom";
import { RetellWebClient } from "retell-client-js-sdk";
import { useDispatch, useSelector } from "react-redux";
import { settoastDetails } from "../../Redux/Slices/toastSlice";
import GenerateReport from "./GenerateReport/GenerateReport";
import { useNavigate } from "react-router-dom";
import EndCallWarningModal from "./components/EndCallWarningModal";

const TemplatesMockInterview = () => {
    // State variables
    const [ischatopen, setIschatopen] = useState(true);
    const [isminmute, setIsmicmute] = useState(false);
    const videoRef = useRef(null);
    const scrollRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);

    // Extract user_id (adjust according to your user object structure)
    const user_id = user?._id || user?.id || "unknown_user";

    // Retell Web Client state variables
    const [retellWebClient] = useState(new RetellWebClient());
    const [isWarningModalVisible, setWarningModalVisible] = useState(false);
    const [isCallActive, setIsCallActive] = useState(false);
    const [callEnd, setCallEnd] = useState(false);
    const [botSpeech, setBotSpeech] = useState("");
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timer, setTimer] = useState(null);
    const [transcriptData, setTranscriptData] = useState([]); // For chat transcript
    const [isAnimating, setIsAnimating] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [error, setError] = useState(null);
    const [isCoinFinished, setIsCoinFinished] = useState(false);

    // Destructure the state passed from the navigate or URL
    const { interview_title, templateType, accessToken, callId,user_type,cost } = location.state || {};

    const interview_callId = localStorage.getItem("interview_callId");

    console.log("interview_callId", interview_callId); // For debugging, can be removed later

    useEffect(() => {
        if (!interview_callId) {
            // Redirect to the specified route if interview_callId is not found
            stopCall();
            stopVideoStream();
            navigate("/buddy/ai-interview-buddy");
        }
    }, []); // Include interview_callId as a dependency


    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [transcriptData]);

    useEffect(() => {
        // Start the Retell call when component mounts
        const processInterview = async () => {
            if (accessToken) {
                try {
                    await retellWebClient.startCall({
                        accessToken: accessToken,
                        sampleRate: 24000,
                    });
                    setIsCallActive(true);
                    localStorage.removeItem("interview_callId");
                } catch (err) {
                    console.error("Error starting call:", err);
                    dispatch(
                        settoastDetails({
                            uniqueId: "",
                            toaststate: true,
                            message: { title: "Error", description: `Error starting the call.` },
                            icon: "error",
                        })
                    );
                }
            }
        };
        if (interview_callId) {
            processInterview();
        }
    }, [accessToken, retellWebClient, dispatch]);

    // Effect to start camera stream once the call is active
    useEffect(() => {
        const startVideoStream = async () => {
            if (accessToken && isCallActive) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            width: { ideal: 1920 },
                            height: { ideal: 1080 },
                            frameRate: { ideal: 60, max: 60 },
                            facingMode: { ideal: "user" },
                        },
                    });

                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        videoRef.current.play();
                    }
                } catch (err) {
                    console.error("Error accessing the camera:", err);
                    dispatch(
                        settoastDetails({
                            uniqueId: "",
                            toaststate: true,
                            message: { title: "Error", description: `Error accessing the camera.` },
                            icon: "error",
                        })
                    );
                }
            }
        };

        if (isCallActive) startVideoStream();

        // Clean up video stream when component unmounts
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
                videoRef.current.srcObject = null;
            }
        };
    }, [isCallActive, accessToken, dispatch]);

    const toggleMute = () => {
        if (isCallActive) {
            if (isminmute) {
                retellWebClient.unmute();
            } else {
                retellWebClient.mute();
            }
            setIsmicmute(!isminmute);
        }
    };

    // Set up event listeners for Retell client
    useEffect(() => {
        const handleCallStarted = () => {
            console.log("Call started");
            // Start timer
            if (!timer) {
                const startTime = Date.now();
                const newTimer = setInterval(() => {
                    const currentTime = Math.floor((Date.now() - startTime) / 1000);
                    setElapsedTime(currentTime);

                    // Check if 17 minutes (1020 seconds) have passed
                    if (currentTime >= 1020) {
                        console.log("17 minutes passed. Ending the call...");
                        handleCallEnded(); // Trigger call end logic
                    }
                }, 1000);
                setTimer(newTimer);
            }
        };

        const handleCallEnded = () => {
            console.log("Call ended");
            setIsCallActive(false);
            setCallEnd(true);
            setShowReportModal(true);
            stopVideoStream();
            if (timer) {
                clearInterval(timer);
                setTimer(null);
            }
        };

        const handleAgentStartTalking = () => {
            console.log("Agent started talking");
            setIsAnimating(true);
        };

        const handleAgentStopTalking = () => {
            console.log("Agent stopped talking");
            setIsAnimating(false);
        };

        const handleUpdate = (update) => {
            console.log("Update:", update);
            if (update?.transcript) {
                // Update the chat transcript
                setTranscriptData(update.transcript);

                // Update the bot's speech
                const agentEntries = update.transcript.filter(
                    (entry) => entry.role === "agent"
                );
                const lastAgentEntry = agentEntries[agentEntries.length - 1];
                if (lastAgentEntry) {
                    setBotSpeech(lastAgentEntry.content);
                }
            }
        };

        const handleError = (err) => {
            console.error("Error:", err);
            stopCall();
        };

        retellWebClient.on("call_started", handleCallStarted);
        retellWebClient.on("call_ended", handleCallEnded);
        retellWebClient.on("agent_start_talking", handleAgentStartTalking);
        retellWebClient.on("agent_stop_talking", handleAgentStopTalking);
        retellWebClient.on("update", handleUpdate);
        retellWebClient.on("error", handleError);

        // Clean up event listeners on component unmount
        return () => {
            retellWebClient.off("call_started", handleCallStarted);
            retellWebClient.off("call_ended", handleCallEnded);
            retellWebClient.off("agent_start_talking", handleAgentStartTalking);
            retellWebClient.off("agent_stop_talking", handleAgentStopTalking);
            retellWebClient.off("update", handleUpdate);
            retellWebClient.off("error", handleError);
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [retellWebClient, timer]);

    // Stop the video stream
    const stopVideoStream = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    // Stop the call function
    const stopCall = () => {
        if (retellWebClient) {
            retellWebClient.stopCall();
            setIsCallActive(false);
            setCallEnd(true);
            setShowReportModal(true);
            if (timer) {
                clearInterval(timer);
                setTimer(null);
            }
        }
    };

    // Handle end call
    const handleEndcall = () => {
        stopCall();
        stopVideoStream();
        // window.close();
    };

    const confirmEndcall = () => {
        setWarningModalVisible(true)
    };

    // Format elapsed time as MM:SS
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs
            .toString()
            .padStart(2, "0")}`;
    };

    const handleGenerateReport = async () => {
        console.log("Generating report...", callId, user_id);
        const maxRetries = 20;
        let attempt = 0;
        const retryDelay = 2500;

        const fetchReportWithRetry = async () => {
            try {
                const data = await fetchReport(callId, user_id);
                setReportData(data);
            } catch (err) {
                if (attempt < maxRetries) {
                    attempt++;
                    console.log(`Retry attempt ${attempt}...`);
                    setTimeout(fetchReportWithRetry, retryDelay);
                } else {
                    console.error("Max retries reached. Could not fetch report.", err);
                    setError(err);
                }
            }
        };

        fetchReportWithRetry();
    };

    const handleCloseWindow = () => {
        setShowReportModal(false);
        navigate("/call-history", {
            state: { callId: callId, callType: "video" },
        });
    };

    const handleCancel = () => {
        setShowReportModal(false);
        navigate("/assessment-report", {
            state: { callId: callId, callType: "video" },
        });
    };

    // Confirm before closing tab or refreshing the page
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isCallActive && !callEnd) {
                e.preventDefault();
                e.returnValue = "Are you sure you want to end this call?";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isCallActive, callEnd]);

    // Confirm before using the browser back button
    useEffect(() => {
        const handlePopState = (e) => {
            if (isCallActive && !callEnd) {
                const confirmLeave = window.confirm("Are you sure you want to end this call?");
                if (!confirmLeave) {
                    // User does not want to leave, so push state forward again
                    window.history.pushState(null, "", window.location.href);
                } else {
                    handleEndcall();
                }
            }
        };

        // Push a new state to avoid immediate back navigation
        window.history.pushState(null, "", window.location.href);

        window.addEventListener("popstate", handlePopState);
        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [isCallActive, callEnd]);

    const confirmEndInterview = () => {
        console.log("User confirmed to end the interview.");
        setWarningModalVisible(false);
        handleEndcall()

    };

    const cancelEndInterview = () => {
        console.log("User canceled the end-interview action.");
        setWarningModalVisible(false);
    };

    return (
        <div className="bg-black fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-screen max-h-full">
            <div className="gap-2 flex flex-col w-[99vw] h-full p-2">
                <div className="flex items-center h-16 bg-black text-black rounded-2xl px-5">
                    <div className="flex items-center">
                        <img
                            className="h-8 rounded-lg mr-2"
                            src={faang_white_logo}
                            alt="Logo"
                        />

                    </div>
                    <div className="ml-auto bg-[#292929] text-white rounded-[21px] px-[23px] py-1">
                        {user?.fullName || "Candidate"}
                    </div>
                </div>

                <div
                    className={`relative h-[90%] w-full flex ${ischatopen ? "justify-between" : "justify-center"
                        } rounded-xl overflow-hidden`}
                >
                    <div className="relative h-full w-full bg-gray-700 rounded-xl overflow-hidden">
                        <div className="absolute z-50 top-2 right-2 h-[42px] text-sm flex items-center rounded-full w-auto bg-black/10 backdrop-filter backdrop-blur-[60px] py-1 px-4 text-slate-50">
                            <div className="flex items-center space-x-2 px-2 text-red-800">
                                <span className="relative flex h-3 w-3">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-800 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                </span>
                                <span>Recording...</span>
                            </div>
                            <span>{formatTime(elapsedTime)}</span>
                        </div>
                        <video
                            ref={videoRef}
                            style={{
                                transform: "scaleX(-1)", // Flip the video horizontally
                            }}
                            className="h-full w-full object-cover transition-all duration-300 rounded-xl"
                            autoPlay
                            playsInline
                        />
                    </div>
                    <div
                        className={`transition-all duration-300 ${ischatopen ? "w-[32%] ml-4" : "w-0 ml-0"
                            } h-full flex flex-col rounded-lg overflow-hidden bg-white shadow-lg`}
                    >
                        {/* Header Section */}
                        {ischatopen && (
                            <div className="p-4 flex justify-between items-center border-b bg-gray-50">
                                <h2 className="font-semibold text-xl text-gray-700">
                                    Live Transcript 🚀
                                </h2>
                                <button
                                    onClick={() => setIschatopen(false)}
                                    className="text-gray-400 hover:text-gray-600 text-2xl focus:outline-none transition-colors"
                                >
                                    &times;
                                </button>
                            </div>
                        )}

                        {/* Transcript Section */}
                        {ischatopen && (
                            <div
                                ref={scrollRef}
                                className="h-full overflow-auto px-4 py-6 space-y-4"
                            >
                                {transcriptData.map((entry, index) => {
                                    const isAgent = entry.role === "agent";
                                    return (
                                        <div
                                            key={index}
                                            className={`flex ${isAgent ? "justify-start" : "justify-end"
                                                }`}
                                        >
                                            <div className="max-w-[75%]">
                                                <div
                                                    className={`flex ${isAgent ? "justify-start" : "justify-end"
                                                        } mb-1`}
                                                >
                                                    <strong
                                                        className={`text-xs font-semibold ${isAgent ? "text-blue-600" : "text-indigo-600"
                                                            }`}
                                                    >
                                                        {isAgent ? "Buddy" : "You"}
                                                    </strong>
                                                </div>
                                                <div
                                                    className={`p-3 text-[13px] leading-relaxed ${isAgent
                                                        ? "bg-blue-100 text-blue-900 rounded-tr-xl rounded-bl-xl rounded-br-xl"
                                                        : "bg-[#4f46e5f2] text-white rounded-tl-xl rounded-bl-xl rounded-br-xl"
                                                        } shadow-sm`}
                                                >
                                                    {entry.content}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="border-t border-[#4a4a4a] border-solid relative h-[12%] w-full flex justify-between items-center text-slate-50 text-xs">
                    <div className="h-full w-full flex justify-between items-center p-2">
                        <div className="w-[30%] flex justify-start items-center space-x-2">
                            <span>{new Date().toLocaleTimeString()}</span>
                            <span>|</span>
                            <span>{`${interview_title || "Googlyness Mock Interview 🚀"}`}</span>
                        </div>

                        <div className="w-full flex justify-center items-center space-x-3 text-lg">
                            <div className="flex flex-col justify-center space-y-1 items-center">
                                <div
                                    onClick={confirmEndcall}
                                    className="w-8 h-8 bg-red-800 rounded-full p-1.5 text-slate-50 cursor-pointer hover:bg-red-500 transition duration-150 ease-in-out flex justify-center items-center"
                                >
                                    <MdCall className="text-xl" />
                                </div>
                                <span className="text-xs text-slate-300">Leave</span>
                            </div>
                            {isminmute ? (
                                <div className="flex flex-col justify-center space-y-1 items-center">
                                    <div
                                        onClick={toggleMute}
                                        className="w-8 h-8 rounded-full p-1.5 bg-gray-800 text-slate-50 cursor-pointer hover:bg-gray-500 transition duration-150 ease-in-out flex justify-center items-center"
                                    >
                                        <IoMdMicOff className="text-xl text-slate-400 cursor-pointer hover:text-slate-100" />
                                    </div>
                                    <span className="text-xs text-slate-300">Unmute</span>
                                </div>
                            ) : (
                                <div className="flex flex-col justify-center space-y-1 items-center">
                                    <div
                                        onClick={toggleMute}
                                        className="w-8 h-8 rounded-full p-1.5 bg-gray-800 text-slate-50 cursor-pointer hover:bg-gray-500 transition duration-150 ease-in-out flex justify-center items-center"
                                    >
                                        <IoMdMic className="text-xl text-slate-400 cursor-pointer hover:text-slate-100" />
                                    </div>
                                    <span className="text-xs text-slate-300">Mute</span>
                                </div>
                            )}
                        </div>

                        <div
                            onClick={() => setIschatopen(!ischatopen)}
                            className="w-[30%] cursor-pointer flex justify-end"
                        >
                            <div className="flex flex-col justify-center space-y-1 items-center">
                                <div className="w-8 h-8 rounded-full p-1.5 bg-gray-800 text-slate-50 cursor-pointer hover:bg-gray-500 transition duration-150 ease-in-out flex justify-center items-center">
                                    <ChatRoundedIcon style={{ fontSize: "18px" }} className="text-xs" />
                                </div>
                                <span className="text-xs text-slate-300">Chat</span>
                            </div>
                        </div>
                    </div>
                </div>
                {showReportModal && (
                    <GenerateReport
                        isCoinFinished={isCoinFinished}
                        onGenerateReport={handleGenerateReport}
                        onCancel={handleCancel}
                        reportData={reportData}
                        requestID={callId}           // Passing callId as requestID
                        user_id={user_id}
                        buddy_type={templateType}    // Passing templateType as buddy_type
                        handleCloseWindow={handleCloseWindow}
                    />
                )}
                {isWarningModalVisible && (
                    <EndCallWarningModal cost={cost} onConfirm={confirmEndInterview} onCancel={cancelEndInterview} user_type={user_type} />
                )}
            </div>
        </div>
    );
};

export default TemplatesMockInterview;
