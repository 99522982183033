import React from 'react';
import googleLogo from "../../../assests/Images/google.png";
import amazonLogo from "../../../assests/Images/amazon.png";


const RenderLogo = ({ tempId }) => {
    if (tempId === 'amazon-leadership-principles') {
        return <img src={amazonLogo} alt="Amazon Logo" className="w-24 mx-auto mb-4" />;
    } else if (tempId === 'google-googlyness') {
        return <img src={googleLogo} alt="Google Logo" className="w-24 mx-auto mb-4" />;
    }
    return null; // Render nothing if tempId doesn't match
};

export default RenderLogo;
