// src/data/templates.js

import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import React from 'react'; // Ensure React is imported to use JSX in data

export const templates = {
    'google-googlyness': {
        title: "Googlyness Mock Interview",
        description: "Showcase how you align with Google's culture of innovation and teamwork.",
        difficulty: "Medium",
        tips: [
            {
                text: "Be authentic. Showcase your unique personality.",
                icon: <EmojiEmotionsOutlinedIcon />
            },
            {
                text: "Think aloud when solving problems. Share your reasoning.",
                icon: <PsychologyOutlinedIcon />
            },
            {
                text: "Ensure a stable internet connection.",
                icon: <WifiOutlinedIcon />
            },
            {
                text: "Ensure a quiet environment.",
                icon: <VolumeOffOutlinedIcon />
            },
            {
                text: "Focus on your thought process over specific answers.",
                icon: <TipsAndUpdatesOutlinedIcon />
            }
        ],
        disclaimer: "Please note: This mock interview is for practice, not affiliated with or endorsed by Google, and is based on publicly shared questions."
    },
    'amazon-leadership-principles': {
        title: "Amazon’s Leadership Principles",
        description: "Test your familiarity with Amazon's Leadership Principles.",
        difficulty: "Medium",
        tips: [
            {
                text: "Provide specific examples of past leadership experiences.",
                icon: <EmojiEmotionsOutlinedIcon />
            },
            {
                text: "Show how you handle conflicts and challenges.",
                icon: <PsychologyOutlinedIcon />
            },
            {
                text: "Maintain a professional environment.",
                icon: <WifiOutlinedIcon />
            },
            {
                text: "Minimize background noise for clarity.",
                icon: <VolumeOffOutlinedIcon />
            },
            {
                text: "Emphasize strategic thinking and decision-making.",
                icon: <TipsAndUpdatesOutlinedIcon />
            }
        ],
        disclaimer: "Please note: This mock interview is for practice, not affiliated with or endorsed by Amazon, and is based on common leadership questions."
    },
    // Add more templates as needed
};
